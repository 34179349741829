import { format, isFuture } from "date-fns"

export function gridClassNames(columnSizes, gapSizes) {
  const columnKeys = Object.keys(columnSizes)
  const gapKeys = Object.keys(gapSizes)

  let classes = ["grid row-gap-10"]
  columnKeys.forEach(k => {
    if (k === "xs") {
      classes.push(`grid-cols-${columnSizes[k]}`)
    } else {
      classes.push(`${k}:grid-cols-${columnSizes[k]}`)
    }
  })

  gapKeys.forEach(k => {
    if (k === "xs") {
      classes.push(`gap-${gapSizes[k]}`)
    } else {
      classes.push(`${k}:gap-${gapSizes[k]}`)
    }
  })
  return classes.join(" ")
}

export function cn(...args) {
  return args.filter(Boolean).join(" ")
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt)
}

export function getBlogUrl(publishedAt, slug) {
  return `/blog/${format(publishedAt, "YYYY/MM")}/${slug.current || slug}/`
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ""
  }
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return ""
      }
      return block.children.map(child => child.text).join("")
    })
    .join("\n\n")
}
